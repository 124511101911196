.input-container {
    width: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .preview-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .color-sample {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid black; /* Visual enhancement for preview */
  }
  
  .color-section {
    margin-bottom: 20px;
  }
  
  .color-title {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .colors-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  
  .color-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .input-section {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .color-input {
    padding: 5px;
    border: 1px solid #ccc;
    width: 100px;
  }
  
  