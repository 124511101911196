@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, p, h1, h2, h3, h4, h5, h6, span, a, li {
  color: #1D4250;
}