.update-container {
  background-color: #E5F8FF;
  border: 1px solid #2183A6;
  border-radius: 8px;
  margin: 0px 0;
  padding: 20px 15px 15px; /* Reduced top padding to make less space above the date */
  position: relative; /* Allows positioning of child elements */
}

.update-date {
  position: absolute;
  top: -5px; /* Adjusted to reduce space above the date */
  right: 15px; /* Aligns with container's padding */
  font-size: 14px;
  color: #888;
  margin-bottom: 10px; /* Increases space between date and title */
}

.update-title {
  margin: 20px 0 px; /* Additional margin on top for more spacing from the date */
  font-size: 18px;
  font-weight: bold;
}

.update-content {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: normal; /* Ensures text is not bold */
}