.account-settings-form {
    width: 30%;
    background-color: #E5F8FF;
}

.account-view{
    margin: 30px;
    background-color: #C5EAF7;
}

/* Base button style */
.account-button {
    padding: 10px 20px;
    margin: 10px 0;
    background-color: #2183a6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect for enabled button */
  .account-button:hover {
    background-color: #1a6b85;
  }
  
  /* Disabled button styles */
  .account-button:disabled {
    background-color: #d3d3d3; /* Slightly greyed out */
    color: #8a8a8a; /* Slightly muted text color */
    cursor: not-allowed;
  }
  
  /* No hover effect for disabled button */
  .account-button:disabled:hover {
    background-color: #d3d3d3;
  }
  