.dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #C5EAF7;
  font-family: 'Poppins', sans-serif;
}

.content-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.left-content {
  display: flex;
  flex-direction: column;
  flex-grow: 2; /* Take up most of the space */
}

.rounded-div {
  background-color: #E5F8FF;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border: 1px solid #2183A6;
}

.event-details {
  max-height: 600px;

}

.social-links {
  flex-grow: 1;
  max-width: 300px; /* Fixed width or maximum width */
  max-height: 200px;
}
.mobile-message{
  display: none;
}
@media (max-width: 768px) {
  .content-area {
    flex-direction: column;
  }
  .form-div {
    display: none; /* Hide the customization form on small screens */
  }
  .mobile-message {
    display: block; /* Show mobile message on small screens */
    padding: 20px;
    text-align: center;
    background-color: rgb(255, 196, 196);
  }
  .social-links, .rounded-div {
    min-width: 90%;
  }

}

.return-home-button {
  width: 100px;
  padding: 10px 20px;
  background-color: #2183a6;
  color: #E5F8FF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.return-home-button:hover {
  background-color: #1a647f;
}
