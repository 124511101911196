/* styles/input-component.css */
.custom-input {
    height: 50px;
    min-width: 300px;
    border-radius: 10px; /* Rounded corners */
    border: 1px solid #ccc; /* Grey outline */
    padding: 10px; /* Padding inside the input */
    box-sizing: border-box; /* Ensure padding doesn't affect total height */
  }
  
  .custom-input:focus {
    outline: none;
    border-color: #2183a6; /* Change border color on focus */
  }
  