.input-container {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between input fields */
    padding: 20px; /* Padding around the container */
    border: 1px solid #ccc; /* Outline around the container */
    border-radius: 5px;
  }
  
  .input-field {
    padding: 10px; /* Padding inside the input field */
    border: 1px solid #ccc; /* Outline for each input */
    border-radius: 5px; /* Rounded corners */
    width: 100%; /* Full width within the container */
    box-sizing: border-box; /* Padding doesn't affect total width */
    background-color: whitesmoke;
  }
  
/* styles/global.css */
.error-message {
  color: red;
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: 12px;
}
