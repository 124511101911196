.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px; /* Add padding at the top */
  z-index: 1000; /* Ensure it's above everything else */
}

.modal-content {
  position: relative; /* Enable positioning for the close button */
  background: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  max-width: 85%;
  max-height: 80vh;
  overflow-y: auto; /* Enable scrolling if content overflows */
  box-sizing: border-box; /* Include padding in width/height */
  margin: auto;
}

/* Specific styling for the "X" button in the modal */
.modal-close-x {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Neutral color */
  cursor: pointer;
}

/* Ensure no hover styles for this button */
.modal-close-x:hover,
.modal-close-x:focus {
  color: #333; /* Keeps the default color */
  background: none; /* Ensure no background changes */
  outline: none; /* Remove focus outline */
}
