/* General Container Styling */
.home-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  background-color: #C5EAF7;
  min-height: 85vh;
}

.home-container h1 {
  color: #1D4250;
}

/* Layout Styling */
.content-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.left-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
}

/* Section Styling */
.rounded-div {
  background-color: #E5F8FF;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #2183A6;
  width: 70vw;
}

.events-section,
.support-section {
  flex-grow: 0.2; /* Occupies smaller space by default */
}

.updates-section {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 100vh;
  max-width: 25vw;
  background-color: #E5F8FF;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #2183A6;
}

/* Section Titles */
.events-section .section-title,
.updates-section .section-title,
.support-section .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
}

/* Button Styling */
button {
  padding: 10px 20px;
  background-color: #2183a6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

button:hover {
  background-color: #1a647f;
}

/* Table Styling */
table {
  width: 100%;
  background-color: #E5F8FF;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-weight: bold;
}

table th {
  color: #1D4250; /* Darker color for table headers */
}

table td {
  color: #2183A6; /* Blue color for data cells */
}

table td a {
  color: #2183A6;
  font-weight: bold;
  text-decoration: none;
}

table td a:hover {
  text-decoration: underline;
}

/* Row Hover Effect */
table tbody tr:hover {
  background-color: rgba(197, 234, 247, 0.3);
  cursor: pointer;
}

/* Responsive Styling */
@media (max-width: 1200px) {
  .content-area {
    flex-direction: column;
  }

  .rounded-div {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 90vw; /* Full-width for small screens */
  }

  .updates-section {
    display: none;
  }
}

@media (max-width: 750px) {
  .mobile-hidden {
    display: none;
  }
}

/* Hover Effect */
.gatheringRow:hover {
  font-weight: bold;
}

/* Transparent Gold Background on Hover */
table tbody tr:hover {
  background-color: rgba(197, 234, 247, 0.3);
  cursor: pointer;
}
