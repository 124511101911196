/* styles/announcement-form.css */
/* .announcement-form {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 20px;
  } */
  
  .announcement-form h3 {
    color: #333;
  }
  
  .announcement-form input, .announcement-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .announcement-form textarea {
    height: 100px;
    resize: none; /* Prevent resizing */
  }
  
  .announcement-form button {
    background-color: #2183a6;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: block; /* Make button block level for full width */
    width: 100%;
  }
  
  .announcement-form button:hover {
    background-color: #1a647f;
  }
  
    .delete-button {
    padding: 5px 10px; /* Padding for the delete button */
    background-color: #e74c3c; /* Red color */
    color: white;
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Hand cursor on hover */
  }