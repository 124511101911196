/* embed-schedule-styles.css */

.schedule-container {
    background-color: #E5F8FF;
    padding: 15px 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    max-width: 1400px;
    width: 100%;
    max-height: 1000px;
    overflow: hidden; /* Prevents scrolling of the entire container */
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .schedule-container h3 {
    text-align: center;
    font-size: 1.7em;
    font-weight: bold;
    color: #1D4250;
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .filter-container {
    display: flex;
    margin-bottom: 20px;
    overflow-x: auto;
  }
  
  /* Scrollable area for the events */
  .scrollable-event-list {
    max-height: 800px; /* Set a maximum height for the scrollable area */
    overflow-y: auto; /* Enables scrolling for this section only */
    padding-right: 10px; /* Optional: add space for a scrollbar */
  }
  
  .event-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .filter-button {
    padding: 10px 16px;
    margin-right: 10px;
    background-color: #2183a6;
    border: 1px solid #cccccc;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    box-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    white-space: nowrap;
  }
  
  .filter-button.active {
    background-color: #1D4250;
    color: #ffffff;
    border-color: #2183A6;
  }
  
  /* Other styles remain the same */
  
  .event-section {
    margin-top: 10px;
  }
  
  .section-header {
    background-color: #1D4250;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .arrow {
    transition: transform 0.3s ease;
    margin-left: 10px;
    color: #ffffff;
  }
  
  .collapsed {
    transform: rotate(-90deg);
  }
  
  .section-events {
    list-style-type: none;
    padding: 0;
  }
  
  .event-item {
    background-color: #2183A6;
    color: #E5F8FF;
    padding: 20px;
    margin: 8px 0;
    border-radius: 10px;
    box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  
  .event-item div {
    font-family: 'Poppins', sans-serif;
    color: #E5F8FF;
  }
  
  .time-container {
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 14px;
    color: #E5F8FF;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .schedule-container {
      padding: 10px;
    }
    .event-item {
      padding: 15px;
    }
  }