/* Container for the modal */
.gathering-creation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin:10px;
  gap: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px;
  margin: auto;
}

/* Form container */
.gathering-input-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

/* Input fields and textarea */
.gathering-input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Date inputs group */
.date-input-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.date-input-group div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.date-input-group label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #1D4250;
}

/* Error messages */
.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: -10px;
}

/* Submit button */
.gathering-submit-button {
  padding: 10px 20px;
  background-color: #2183a6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.gathering-submit-button:hover {
  background-color: #1a647f;
}
