.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center; /* Center modal vertically */
    z-index: 1000; /* Ensure it's above everything else */
    padding-top: 40px; /* Add padding at the top */
  }
  
  .modal-content {
    position: relative;
    background: #fff;
    padding: 30px;
    margin: 10px;
    border-radius: 12px;
    min-width: 40%;
    max-width: 500px; /* Restrict max-width to avoid large modals */
    box-sizing: border-box;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
    display: flex;
    flex-direction: column; /* Stack the children vertically */
    align-items: center; /* Center content horizontally */
  }
  
  .modal-close-x {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }
  
  .modal-close-x:hover,
  .modal-close-x:focus {
    color: #FF0000; /* Change color when hovered/focused */
    outline: none;
  }
  
  .sponsor-modal-h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
  }
  
  .sponsor-modal-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
    color: #333;
    width: 100%; /* Ensures labels align with inputs */
  }
  
  .sponsor-modal-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #333;
    box-sizing: border-box; /* Ensures padding doesn't affect width */
  }
  
  .sponsor-modal-input:focus {
    outline: none;
    border-color: #2183a6; /* Focused input border color using your blue */
  }
  
  .sponsor-description-input {
    resize: vertical;
    min-height: 100px;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .checkbox-container {
    margin-bottom: 20px;
    width: 100%; /* Ensure the checkbox section takes full width */
  }
  
  .checkbox-container label {
    font-weight: 500;
  }
  
  .submit-button {
    padding: 16px 24px; /* Increased padding for larger buttons */
    background-color: #2183a6; /* Using your blue for the submit button */
    color: white;
    width: 45%; /* Adjusted width for buttons */

  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .sponsor-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%; /* Ensure buttons are aligned horizontally */
    gap: 10px; /* Added gap between buttons */
  }