/* styles/event-creation-form.css */
.event-input-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between sections */
  padding: 20px; /* Padding for the container */
  border: 1px solid #ccc; /* Outline for the container */
  border-radius: 5px; /* Rounded corners */
}

.event-input-field {
  padding: 10px; /* Padding for the input */
  border: 1px solid #ccc; /* Outline */
  border-radius: 5px; /* Rounded corners */
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between input fields */
}

.location-section, .date-section {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between elements */
}

.location-buttons-container,
.date-buttons-container,
.event-type-buttons-container {
  display: flex;
  flex-wrap: nowrap; /* Ensure buttons stay in a single row */
  overflow-x: auto; /* Enable horizontal scrolling */
  gap: 10px; /* Add space between buttons */
  padding: 10px 0; /* Add vertical padding for better spacing */
  max-width: 100%; /* Limit container width to fit its parent */
  overflow-y: hidden; /* Prevent vertical scrolling */
}

.location-button,
.date-button,
.event-type-button {
  flex-shrink: 0; /* Prevent buttons from shrinking to fit */
  padding: 10px 16px;
  background-color: #2183a6;
  border: 1px solid #cccccc;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  box-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  min-height: 60px;
  max-width: 200px; /* Restrict maximum width */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  text-align: center; /* Center-align text within the button */
}

.location-buttons-container::-webkit-scrollbar,
.date-buttons-container::-webkit-scrollbar,
.event-type-buttons-container::-webkit-scrollbar {
  height: 8px; /* Set the scrollbar height */
}

.location-buttons-container::-webkit-scrollbar-thumb,
.date-buttons-container::-webkit-scrollbar-thumb,
.event-type-buttons-container::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* Customize scrollbar thumb color */
  border-radius: 4px; /* Round the scrollbar thumb edges */
}

.location-buttons-container::-webkit-scrollbar-track,
.date-buttons-container::-webkit-scrollbar-track,
.event-type-buttons-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Customize scrollbar track color */
}




.location-button.active, .date-button.active, .event-type-buttons {
  background-color: #1D4250;
  color: #ffffff;
  border-color: #2183A6;
}

.add-event-button {
  padding: 10px 20px; /* Padding for the button */
  background-color: #2183a6; /* Blue color */
  color: white;
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Hand cursor on hover */
}

.add-event-button:hover {
  background-color: #1a647f /* Darker blue on hover */
}

.form-section h4, .location-section h5, .date-section h5 {
  margin: 0;
  font-size: 16px;
  color: #333;
}
