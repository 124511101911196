.participant-dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #c5eaf7;
  font-family: "Poppins", sans-serif;
}

.dashboard-title {
  color: #1d4250;
}

.dashboard-content {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Make it responsive */
}

.participant-content {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.rounded-div {
  background-color: #e5f8ff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.participant-gatherings {
  margin-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-field {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 4px;
}

.profile-image {
  margin-top: 10px;
  height: 150px;
  object-fit: cover;
  border: 1px solid #ccc;
}

.edit-mode-button {
  padding: 10px 15px;
  margin-right: 5px;
  background-color: #2183a6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.edit-mode-button:hover {
  background-color: #1a647f;
}

.return-home-button {
  width: 100px;
  padding: 10px 20px;
  background-color: #2183a6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.return-home-button:hover {
  background-color: #1a647f;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal-content {
  background: #fff;
  width: 80%;
  max-height: 90%;
  border-radius: 10px;
  overflow: auto;
  position: relative;
  padding: 20px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-header {
  text-align: center;
  margin-bottom: 20px;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}
