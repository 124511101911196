/* src/styles/components/dropdown.css */

.dropdown {
    position: relative;
    display: inline-block;
    text-decoration: none;

  }
  
  .dropdown-button {
    padding: 10px 20px;
    background-color: #2183a6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;

  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 200px;
    z-index: 1;
    text-decoration: none;

  }
  
  .dropdown-item {
    padding: 10px;
    color: #1d4250;
    text-decoration: none;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown-item a {
    color: inherit;
    text-decoration: none;
  }
  