/* styles/participant-registration-form.css */
.participant-registration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

.participant-input-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
}

.participant-input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 93%;
}

.participant-submit-button {
  padding: 10px 20px;
  background-color: #2183a6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.participant-submit-button:hover {
  background-color: #1a647f;
}

/* Add styles to the form elements specifically */
.participant-registration-container form input,
.participant-registration-container form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.participant-registration-container form textarea {
  height: 100px;
  resize: vertical;
}
