/* styles/auth-view.css */
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh; /* Full viewport height */
    padding: 20px;
    background-color: #C5EAF7;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Gap between elements */
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 20px;
    background-color: #E5F8FF;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border: 1px solid #2183A6;
    min-width: 400px;
  }
  
  .input-group input {
    width: 100%; /* Adjust as needed */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-group button {
    padding: 10px 20px;
    border: none;
    background-color: #2183a6;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .input-group button:hover {
    background-color: #1a647f; /* Darker shade on hover */
  }
  

  .message {
    font-size: small;
  }