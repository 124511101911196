.event-details-modal {
  padding: 30px; /* Increased from 20px for more room */
  max-width: 700px; /* Increased width for a larger modal */
  min-width: 500px;
  margin: 40px auto; /* Some extra top/bottom margin */
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  text-align: left; /* Ensure text is left-aligned */
}

.event-details-modal h2 {
  margin-bottom: 1.5rem;
  font-weight: 600;
  text-align: left; /* Left-justify the heading */
}

.event-details-modal p {
  margin: 0.75rem 0; /* Additional spacing between lines */
}

/* Participant list container */
.participant-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.participant-item {
  display: flex;
  justify-content: flex-start; /* Start on the left */
  align-items: center;
  padding: 12px 10px; /* More padding for comfort */
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.participant-item span {
  font-size: 16px;
  font-weight: bold;
}

/* Narrow rank span, name gets the rest */
.participant-item span:first-of-type {
  width: 10px;        /* Decreased width for the rank */
  padding: 0 10px;
  margin-right: 8px;  /* Small gap between rank and name */
}

.participant-item span:nth-of-type(2) {
  flex: 1;            /* Expand for the name */
  padding-right: 20px;
}

/* Reorder buttons container */
.reorder-buttons {
  display: flex;
  gap: 10px;
}

/* Darker teal / blue button color: #2183a6 */
.reorder-buttons button,
.save-button,
.close-button {
  padding: 7px 14px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #2183a6; /* Updated color */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reorder-buttons button:hover,
.save-button:hover,
.close-button:hover {
  background-color: #19728f; /* Slightly darker teal on hover */
}

/* Remove button styling (red) overrides */
.remove-button {
  background-color: #e74c3c !important;
}

.remove-button:hover {
  background-color: #c0392b !important;
}

/* Disable arrow buttons if out of range */
.reorder-buttons button:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}
