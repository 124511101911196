.upload-button {
    padding: 10px;
    margin-top: 10px;
    border: 2px solid #2183a6;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .upload-button:hover {
    background-color: #e0e0e0;
    border-color: #1b6e87;
  }
  
  .map-image {
    margin-top: 20px;
    max-width: 100%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 5px;
  }

  .logo-image {
    margin-top: 20px;
    max-width: 50%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 5px;
  }

  /* Hide the default file input */
  .upload-button {
    display: none; /* Ensures the default file input is hidden */
  }

/* Style the upload button to look like other buttons */
.upload-button-label {
  display: inline-block;
  background-color: #2183a6;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button-label:hover {
  background-color: #1b6e87;
}

/* Adjust the appearance of uploaded images */
.map-image,
.logo-image {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
  border: 2px solid #ddd;
  border-radius: 5px;
}
