.schedule-container {
    background-color: #E5F8FF;
    padding: 20px;
    overflow: scroll !important;
}

.filter-container {
    display: flex;
    margin-bottom: 20px;
    overflow-x: auto;
}

.filter-button {
    padding: 10px 16px;
    margin-right: 10px;
    background-color: #2183a6;
    border: 1px solid #cccccc;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    box-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.filter-button.active {
    background-color: #1D4250;
    color: #ffffff;
    border-color: #2183A6;
}

.event-list {
    list-style-type: none;
    padding: 0;
}

.event-section {
    margin-top: 10px;
}

.section-header {
  background-color: #1D4250;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

/* Styles for the section header arrow */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.arrow {
  transition: transform 0.3s ease;
  margin-left: 10px;
}

/* Rotate the arrow when the section is collapsed */
.collapsed {
  transform: rotate(-90deg); /* Rotates the arrow 90 degrees */
}




.section-events {
    list-style-type: none;
    padding: 0;
}

/* For the event item background and text color */
.event-item {
  background-color: #2183A6; /* Matches your app's tile background */
  color: #E5F8FF;
  padding: 20px;
  margin: 8px 0;
  border-radius: 10px;
  box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
  position: relative;
}

.event-item div {
  font-family: 'Poppins', sans-serif; /* Apply Poppins font */
  color: #E5F8FF; /* Customize based on app theme */
}


/* For the time display */
.event-item .time-container {
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 14px;
  color: #E5F8FF; /* Adjust to match your theme color */
  font-weight: bold;
}

/* For buttons (edit, delete) positioning */
.event-item .buttons-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
}

.edit-button, .delete-button {
  background-color: #1D4250;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.delete-button {
  background-color: #e74c3c;
}

.edit-button:hover, .delete-button:hover {
  opacity: 0.8;
}



.edit-button {
    background-color: #1D4250;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right:3px;
    margin-top:10px;
}

.edit-button:hover {
  background-color: #122932;
}

.delete-button {
    background-color: #e74c3c;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right:3px;
    margin-top:10px;
}

.delete-button:hover {
  background-color: #bc2d1d;
}


.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75); /* Dark overlay */
  }
  
  .ReactModal__Content {
    margin: auto; /* Center the modal */
    padding: 20px; /* Add padding */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow */
  }
  
  .confirm-button, .cancel-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .confirm-button {
    background-color: #d9534f; /* Red color */
    color: white;
  }
  
  .confirm-button:hover {
    background-color: #c9302c;
  }
  
  .cancel-button {
    background-color: #5bc0de; /* Blue color */
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #31b0d5;
  }

  @media (max-width: 768px) {
    .event-item {
      padding: 15px;
    }
  
    .edit-button, .delete-button {
      padding: 8px;
    }
  }
  