.modal-content {
    background-color: #E5F8FF;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: auto;
    text-align: center;
  }
  
  .support-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 0.9em;
    color: #333;
  }
  
  .support-form input,
  .support-form textarea {
    width: 90%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .send-button,
  .close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .send-button {
    background-color: #2183a6;
    color: white;
  }
  
  .close-button {
    background-color: #ccc;
    color: black;
  }
  
  .close-button:hover {
    color: #E5F8FF;
  }