/* styles/components/sidebar-component.css */
.sidebar {
  width: 200px; /* Adjust width as needed */
  background-color: #E5F8FF; /* Light grey background */
  padding: 10px;
  height: 100vh; /* Full height */
  box-shadow: 2px 0px 5px rgba(0,0,0,0.1); /* Subtle shadow on the right */
}

.tab-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tab-list li {
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.tab-list li:hover {
  background-color: #ddd; /* Light grey on hover */
}

.tab-list .active {
  background-color: #2183A6; /* Blue for active tab */
  color: white;
  position: relative;
}

.tab-list li.active:hover,
.tab-list li.active:hover::after {
  background-color: #1a647f; /* Darker blue on hover for both tab and extension */
}

/* Responsive adjustments if needed */
@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Full width for smaller screens */
    height: auto; /* Auto height based on content */
    position: relative; /* Adjust position for mobile */
  }

  .tab-list li.active::after {
    display: none; /* Hide extension on smaller screens */
  }
}
