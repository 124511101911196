/* styles/gathering-customization-form.css */
.customization-container {
    display: flex; /* Horizontal layout */
    flex-direction: row; /* Sidebar on the left, content on the right */
    height: 100vh; /* Full viewport height */
  }
  
  .sidebar {
    width: 200px; /* Fixed width for the sidebar */
    border-right: 1px solid #ccc; /* Border to separate from content */
    padding: 10px; /* Padding within the sidebar */
  }
  
  .content {
    flex: 1; /* Main content area takes the rest of the space */
    padding-left: 20px; /* Space between sidebar and content */
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
  }
  
  .tab-list {
    list-style-type: none; /* No bullet points */
    padding: 0;
  }
  
  .tab-list li {
    padding: 10px;
    cursor: pointer; /* Cursor changes on hover */
    text-align: left;
    border-bottom: 1px solid transparent; /* Border to indicate active tab */
  }
  
  .tab-list li:hover {
    background-color: #C5EAF7; /* Hover effect */
  }
  
  .tab-list .active {
    background-color: #2183a6; /* Active tab color */
    color: white;
    border-bottom: 1px solid white; /* Border for active tab */
  }
  
  .content {
    flex: 3; /* Main content takes more space */
    padding-left: 20px; /* Space between sidebar and content */
  }
  
  .save-button {
    padding: 10px 20px;
    background-color: #2183a6; /* Blue button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
  
  .save-button:hover {
    background-color: #1a647f; /* Darker blue on hover */
  }
  
  .save-button:disabled {
    background-color: #ccc; /* Greyed out when disabled */
    cursor: not-allowed; /* Indicate unclickable */
  }


  

  /* styles/gathering-customization-form-component.css */
.input-container {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between input fields */
    padding: 20px; /* Padding around the container */
    border: 1px solid #ccc; /* Outline around the container */
    border-radius: 5px;
  }
  
  .input-field {
    padding: 10px; /* Padding inside the input field */
    border: 1px solid #ccc; /* Outline for each input */
    border-radius: 5px; /* Rounded corners */
    width: 100%; /* Full width within the container */
    box-sizing: border-box; /* Padding doesn't affect total width */
  }
  
  .input-field:focus {
    outline: none; /* Remove default outline on focus */
    border-color: #2183a6; /* Change border color on focus */
  }

  .input-label {
    outline: none; /* Remove default outline on focus */
    margin: 0px;
  }
  
  .save-button {
    padding: 10px 20px; /* Padding for the button */
    background-color: #2183a6; /* Blue color */
    color: white;
    border: none;
    border-radius: 5px; /* Rounded corners for the button */
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #1a647f; /* Darker blue on hover */
  }

.table-container {
  overflow: scroll;
}

  /* styles/gathering-customization-form-component.css */
.map-image {
    border: 1px solid #ccc; /* Outline */
    border-radius: 5px; /* Rounded corners */
    max-width: 100%; /* Maximum width */
  }
  
  .logo-image {
    border: 1px solid #ccc; /* Outline */
    max-width: 100px; /* Maximum width */
  }

  /* styles/announcements.css */
.section {
  padding: 20px;
  background-color: #f9f9f9; /* Light background for the section */
  border-radius: 5px; /* Rounded corners for the section */
  margin-top: 20px; /* Space above the section */
}

.section h2 {
  color: #333; /* Dark grey color for the section title */
  margin-bottom: 10px; /* Space below the title */
}

.announcements-list {
  list-style: none; /* Remove default list bullets */
  padding: 0; /* No padding */
  margin: 0; /* No margin */
}

.announcements-list li {
  background-color: #fff; /* White background for each list item */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 15px; /* Padding inside each list item */
  margin-bottom: 10px; /* Space between list items */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.announcements-list li h3 {
  color: #2183a6; /* Blue color for the announcement title */
  margin-top: 0; /* No top margin to align text better */
  margin-bottom: 5px; /* Small margin below the title */
}

.announcements-list li p {
  margin: 0; /* No margin to maximize space */
  color: #666; /* Light grey color for text, easier on the eyes */
}

.edit-mode-button {
  width: 200px;
  padding: 10px;
  color: white;
  background-color: #2183a6;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.tab-title {
  color: #1D4250;
}
/* styles/components/gathering-customization-form-component.css */
.event-creation-toggle {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #2183a6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}

.event-creation-toggle:hover {
  background-color: #1a647f;
}

.csv-button-container {
  display: flex;             /* Arrange child buttons in a row */
  justify-content: space-between; /* Ensure space between the buttons */
  gap: 10px;                 /* Add spacing between the buttons */
  margin: 10px 0;            /* Spacing around the container */
}

.csv-button {
  flex: 1;                   /* Each button takes up equal space (half the width of the parent) */
  padding: 10px 20px;
  background-color: #2183a6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
}

.csv-button:hover {
  background-color: #1a647f;
}



.embed-code-preview {
  background-color: #f5f5f5; /* Light background for embed code */
  border: 1px solid #ccc; /* Border around the preview */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding inside the code block */
  font-family: "Courier New", Courier, monospace; /* Monospace font */
  white-space: pre-wrap; /* Preserve formatting and allow wrapping */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  color: #333; /* Dark text color for readability */
}


@media (max-width: 768px) {
  .sidebar {
    width: 100%;  /* Full width */
    position: fixed; /* Fixed position */
    height: 50px; /* Reduced height */
    overflow: hidden; /* Hide overflow */
    z-index: 100; /* Make sure it's on top */
  }

  .sidebar.expanded {
    height: auto; /* Expand to show all items */
    overflow: auto;
  }

  .tab-list li {
    display: block; /* Stack vertically */
  }

  .input-container, .input-field, .save-button, .map-image, .logo-image {
    width: 100%;  /* Full width for better control */
    padding: 10px;  /* Adjust padding for smaller screens */
    margin: 10px 0; /* More vertical space between elements */
  }
}


